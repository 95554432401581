import validate from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45preview_45url_45global from "/opt/build/repo/middleware/01.preview-url.global.ts";
import _05_45load_45database_45global from "/opt/build/repo/middleware/05.load-database.global.ts";
import _10_45load_45content_45global from "/opt/build/repo/middleware/10.load-content.global.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45preview_45url_45global,
  _05_45load_45database_45global,
  _10_45load_45content_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}