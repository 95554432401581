// Prismic links to /preview with a document ID that we must lookup and redirect to
import { gql } from "graphql-tag";

export default defineNuxtRouteMiddleware(async (to) => {
  if (to.path.startsWith("/preview") && typeof to.query.token === "string" && typeof to.query.documentId === "string") {
    const { data } = await useAsyncQuery(
      gql`query ($id: String!) { allPages(id: $id, lang: "en-gb") { edges { node { _meta { uid } } } } }`,
      { id: to.query.documentId },
    );

    // @ts-expect-error need to implement typing for the allPages query
    const uid = String(data.value?.allPages.edges[0].node._meta.uid);

    if (uid) {
      return await navigateTo(`/${uid}`, {
        redirectCode: 307,
      });
    }
  }
});
