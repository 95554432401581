import { createPrismicLink } from "apollo-link-prismic";

export default defineNuxtPlugin(() => {
  const nuxtApp = useNuxtApp();

  const { public: { prismicRepo, prismicAccessToken } } = useRuntimeConfig();

  nuxtApp.$apollo.clients.default.setLink(createPrismicLink({
    repositoryName: prismicRepo,
    accessToken: prismicAccessToken,
  }));
});
