import Papa from "papaparse";
import type { Database } from "~/types/database.js";

export default defineNuxtRouteMiddleware(async () => {
  const nuxtApp = useNuxtApp();

  // We don't need to load database if we've just received it via SSR
  if (import.meta.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) {
    return;
  }

  const { data } = await useAsyncQuery<{
    database: {
      last_updated: string;
      csv?: {
        url: string; };
      xls?: { url: string };
    };
  }>(
    gql`
      query {
        database(uid: "database", lang: "en-gb") {
          csv {
            ... on _FileLink {
              url
            }
          }
          xls {
            ... on _FileLink {
              url
            }
          }
          last_updated
        }
      }
    `,
  );

  if (data.value) {
    const parse = async (url: string) => {
      // ofetch seems to read text/csv as latin1 but thinks its utf8
      const dataBuffer = await $fetch<ArrayBuffer>(url, { responseType: "arrayBuffer" });

      const rawData = new TextDecoder("latin1").decode(dataBuffer);

      // Disable eslint rule here because Papaparse is crap (bring on v6)
      // eslint-disable-next-line @typescript-eslint/await-thenable
      const { data } = await Papa.parse<Database["data"][0]>(
        rawData,
        {
          header: true, // This uses the first line as object keys
          transform: (value) => value.trim(), // This trims whitespace from around values
          transformHeader: (value) => value.trim(),
          skipEmptyLines: true,
        },
      );

      return data;
    };

    useDatabase().value = {
      lastUpdated: data.value.database.last_updated,
      url: data.value.database.csv?.url ?? "",
      download: data.value.database.xls?.url ?? "",
      data: data.value.database.csv ? await parse(data.value.database.csv.url) : [],
    };
  }
});
