<script setup lang="ts">
import { asHTML } from "@prismicio/helpers";
import type { RTNode } from "@prismicio/types";
import type { Copy } from "~/types/prismic.js";

const props = defineProps<{
  blocks?: Copy[];
}>();

const nonBlank = computed(() => {
  return parsedBlocks.value.filter((block) => block.text).length > 0;
});

const getHtml = computed(() => {
  const newBlocks = props.blocks?.map((block) => {
    const newBlock = Object.assign({}, block);

    newBlock.spans = [];

    return newBlock;
  });

  return asHTML(newBlocks as [RTNode], () => "/");
});

const parsedBlocks = computed(() => {
  // This parses the text within each block, injecting markup from Prismic as defined in "spans"
  const blocks = props.blocks?.map((block) => {
    const newBlock: Copy = JSON.parse(JSON.stringify(block));

    if (newBlock.spans?.length) {
      // Reverse the spans since character indexes will change as we inject
      newBlock.spans.sort((a, b) => b.start - a.start);

      newBlock.spans.forEach((span, index) => {
        let type, attrs;

        if (span.type === "hyperlink") {
          type = "a";
        } else {
          type = span.type;
        }

        if (!type) {
          return;
        }

        if (span.data) {
          attrs = ` href="${span.data.url ?? ""}" target="${span.data.target ?? ""}"`;
        } else {
          attrs = "";
        }

        newBlock.text = [
          newBlock.text.slice(0, span.start),
          `<${type}${attrs}>`,
          newBlock.text.slice(span.start, span.end),
          `</${type}>`,
          newBlock.text.slice(span.end),
        ].join("");

        delete newBlock.spans?.[index]; // Prevent span being re-applied
      });
    }

    return newBlock;
  }) ?? [];

  // This finds list items and wraps them in a ul or ol
  const grouped: Copy[] = [];

  let subgroup: Copy = {
    type: "",
    spans: [],
    text: "",
  };

  blocks.forEach((block) => {
    if (block.type === "list-item") {
      subgroup.type = "ul";

      if (!subgroup.items) {
        subgroup.items = [block];
      } else {
        subgroup.items.push(block);
      }
    } else if (block.type === "o-list-item") {
      subgroup.type = "ol";

      if (!subgroup.items) {
        subgroup.items = [block];
      } else {
        subgroup.items.push(block);
      }
    } else {
      if (Object.keys(subgroup).length) {
        grouped.push(subgroup);

        subgroup = {
          type: "",
          spans: [],
          text: "",
        };
      }

      grouped.push(block);
    }
  });

  if (
    props.blocks?.length
    && ["list-item", "o-list-item"].includes(
      props.blocks[props.blocks.length - 1]?.type ?? "",
    )
  ) {
    grouped.push(subgroup);
  }

  return grouped;
});

const getLinkUrl = (string: string) => /<a href="([^"]+)"/.exec(string)?.[1];

const getLinkText = (string: string) => /<.+>(.+?)<.+>/.exec(string)?.[1];
</script>

<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="nonBlank">
    <template v-for="(block, index) in parsedBlocks">
      <div
        v-if="block.type === 'image'"
        :key="`image_${index}`"
        class="image"
      >
        <NuxtImg :src="block.url" />
      </div>
      <ul
        v-else-if="block.type === 'ul'"
        :key="`ul_${index}`"
      >
        <li
          v-for="(item, itemIndex) in block.items"
          :key="`${index}_${itemIndex}`"
          v-html="item.text"
        />
      </ul>
      <ol
        v-else-if="block.type === 'ol'"
        :key="`ol_${index}`"
      >
        <li
          v-for="(item, itemIndex) in block.items"
          :key="`${index}_${itemIndex}`"
          v-html="item.text"
        />
      </ol>
      <h1
        v-else-if="block.type === 'heading1'"
        :id="block.text"
        :key="`heading1_${index}`"
        v-html="block.text"
      />
      <h2
        v-else-if="block.type === 'heading2'"
        :id="block.text"
        :key="`heading2_${index}`"
        v-html="block.text"
      />
      <h3
        v-else-if="block.type === 'heading3'"
        :id="block.text"
        :key="`heading3_${index}`"
        v-html="block.text"
      />
      <h4
        v-else-if="block.type === 'heading4'"
        :id="block.text"
        :key="`heading4_${index}`"
        v-html="block.text"
      />
      <h5
        v-else-if="block.type === 'heading5'"
        :id="block.text"
        :key="`heading5_${index}`"
        v-html="block.text"
      />
      <h6
        v-else-if="block.type === 'heading6'"
        :id="block.text"
        :key="`heading6_${index}`"
        v-html="block.text"
      />
      <PrismicLink
        v-else-if="
          block.type === 'paragraph' && block.text.match(/^Button <a[^>+]>$/)
        "
        :key="`prismic-link_${index}`"
        :link="{
          __typename: 'Link',
          url: getLinkUrl(block.text),
          target: '_blank',
        }"
      >
        {{ getLinkText(block.text) }}
      </PrismicLink>
      <p
        v-else-if="block.type === 'paragraph'"
        :key="`paragraph_${index}`"
        v-html="block.text.replaceAll('https:///', '/')"
      />
      <div
        v-else-if="block.type === 'embed'"
        :key="`embed_${index}`"
        class="embed"
        v-html="block.oembed ? block.oembed.html : ''"
      />
      <div
        v-else
        :key="`text_${index}`"
        v-html="block.text"
      />
    </template>
  </div>
  <div
    v-else
    v-html="getHtml"
  />
</template>

<style lang="scss" scoped>
section {
  margin: 120px auto;

  @include mobile {
    margin-top: 40px;
  }

  .image {
    text-align: center;

    img {
      width: 80%;

      @include mobile {
        width: 100%;
      }
    }
  }

  p {
    min-height: 0.1px;
  }

  .embed {
    overflow: hidden;

    /* 16:9 aspect ratio */
    padding-top: 56.25%;
    position: relative;

    :deep(iframe) {
      border: 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}
</style>
