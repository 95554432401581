<script setup lang="ts">
import type { Link } from "~/types/prismic.js";
import { NuxtLink } from "#components";

const props = defineProps<{
  link?: Link;
}>();

const tag = computed(() => {
  if (!props.link || !Object.keys(props.link).length) {
    return "div";
  }

  return props.link._meta ? NuxtLink : "a";
});

const to = computed(() => {
  if (!props.link) {
    return null;
  }

  if (props.link._meta && props.link.__typename === "Page") {
    if (props.link._meta.uid === "index") {
      return "/";
    } else {
      return `/${props.link._meta.uid.replaceAll("_", "/")}`;
    }
  }

  return props.link.url?.replaceAll("https:///", "/");
});

const href = computed(() => {
  return tag.value === "div" ? null : to.value;
});

const target = computed(() => {
  if (!props.link) {
    return null;
  }

  return props.link.target;
});
</script>

<template>
  <component
    :is="tag"
    v-if="to || href"
    v-bind="{ ...(tag === NuxtLink ? { to } : { href }) }"
    :target="target"
    class="prismic-link"
  >
    <slot />
  </component>
</template>
