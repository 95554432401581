<script setup lang="ts">
import type { Footer } from "~/types/prismic.js";
import footerQuery from "~/gql/footerQuery.gql";

const { data } = await useAsyncQuery<{ footer?: Footer }>(footerQuery);

const content = computed(() => data.value?.footer);
</script>

<template>
  <Transition
    name="fade"
    mode="out-in"
    appear
  >
    <footer v-if="content">
      <MarginContainer>
        <div>
          <PrismicLink
            v-for="(social, index) in content.socials"
            :key="`social${index}`"
            :link="social.link"
          >
            <Icon
              v-if="social.type === 'Instagram'"
              name="icons:instagram"
              :size="20"
            />
            <Icon
              v-else-if="social.type === 'Twitter'"
              name="icons:twitter"
              :size="20"
            />
            <Icon
              v-else-if="social.type === 'LinkedIn'"
              name="icons:linked-in"
              :size="20"
            />
          </PrismicLink>
        </div>
        <div
          v-if="content.copyright"
          class="copyright"
        >
          <PrismicText :blocks="content.copyright" />
        </div>
      </MarginContainer>
    </footer>
  </Transition>
</template>

<style lang="scss" scoped>
footer {
  width: 100%;
  background-color: rgba($blue1, 0.95);
  color: $white1;

  section {
    display: flex;
    padding: 40px;
    background-color: transparent;

    @include mobile {
      padding: 24px 16px;
      flex-direction: column;
    }

    > div {
      display: flex;
      min-width: 40%;

      @include mobile {
        justify-content: center;
      }

      .prismic-link {
        margin-right: 32px;
        color: $white1;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .copyright {
      font-size: rem-calc(12px);
      color: $white1;

      @include mobile {
        margin-top: 16px;
        text-align: center;
      }
    }
  }
}
</style>
