import { setContext } from "@apollo/client/link/context";
import CookieApi from "js-cookie";
import { parse as parseCookieHeader } from "cookie";
import contentQuery from "~/gql/contentQuery.gql";
import type { Page } from "~/types/prismic.js";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const nuxtApp = useNuxtApp();

  const prismicCookieKey = "io.prismic.preview";

  // Fetching the preview cookie is different in SSR vs browser
  const previewToken = import.meta.client ? CookieApi.get(prismicCookieKey) : parseCookieHeader(useRequestHeader("Cookie") ?? "")[prismicCookieKey];

  // We don't need to load content if we've just received it via SSR
  if (import.meta.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) {
    return;
  }

  if (previewToken) {
    const previewLink = setContext((_, context) => ({
      ...context,
      headers: {
        ..."headers" in context ? context.headers : {},
        ...(previewToken ? { "Prismic-ref": previewToken } : {}),
      },
    }));

    nuxtApp.$apollo.clients.default.setLink(previewLink.concat(nuxtApp.$apollo.clients.default.link));
  }

  const router = useRouter();

  const content = useContent();

  // This is done so that a page navigation can be detected for transition purposes
  if (to.path !== from.path) {
    content.value = null;
  }

  const database = useDatabase();

  const uid = Array.isArray(to.params.slug)
    ? (to.params.slug[0] === "database" && to.params.slug[1] === "policy" && to.params.slug[2]
        ? "policy"
        : to.params.slug.join("_"))
    : to.params.slug === "" ? "index" : to.params.slug;

  const policy = uid === "policy" ? database.value?.data.find((row) => row.Slug === to.params.slug?.[2]) : undefined;

  const { data, error } = await useAsyncQuery<{ page?: Page }>(contentQuery, { uid });

  if (error.value) {
    showError(error.value);

    throw error.value;
  } else if ((uid === "policy" && !policy) || (!data.value?.page && !policy)) {
    if (!to.path.startsWith("/api") && router.resolve(to).name === "slug") {
      return abortNavigation(createError({
        status: 404,
        statusText: "Page not found",
      }));
    }
  } else {
    if (data.value?.page) {
      content.value = data.value.page;
    }

    if (policy) {
      usePolicy().value = policy;
    }
  }
});
